import logo from "./logo.svg";
import "antd/dist/antd.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Row, Col, Tabs } from "antd";
import Landing from "./pages/Landing";
import Card from "./components/Card";
import kdlogo from "./img/logo.png";
import dp from "./img/dp.jpg";
import tranzitlogo from "./img/logos/tranzit-logo.svg";
import hymnsenselogo from "./img/logos/hymnsense-logo.svg";
import youtubelogo from "./img/logos/youtube-logo.svg";
import linkedinlogo from "./img/logos/linkedin-logo.svg";
import twitterLogo from "./img/logos/twitter-logo.svg";
import tradebaseLogo from "./img/logos/tradebase-logo.svg";
import imaxLogo from "./img/logos/imax-logo.svg";
import effluxLogo from "./img/logos/efflux-logo.svg";
import blog from "./img/logos/blog.png";
import github from "./img/logos/github.png";
import bajaj from "./img/logos/bajaj.png";
import spotify from "./img/logos/spotify.png";
import letterboxd from "./img/logos/letterboxd.png";
import "./css/card.css";
const { TabPane } = Tabs;

function App() {
  return (
    <div className="App">
      <div className="logo-div">
        <img src={kdlogo} width={100}></img>
      </div>
      {/* <div className="comingsoon">
        <h1>Coming Soon.</h1>
      </div> */}
      <div className="dp-div">
        <Row>
          <Col>
            <img className="display-photo" src={dp} width={200}></img>
          </Col>
          <Col style={{ marginLeft: "50px" }}>
            <div className="dp-text">
              <h1>Hi, I'm Kaustubh. </h1>
              <h2>
                nerd for products - i make videos on tech, build software and
                talk about movies.
              </h2>
              <h2>(i try.)</h2>
            </div>
          </Col>
          {/* <h1>This is my digital footprint and my ✨oeuvre✨</h1> */}
        </Row>
      </div>
      <div className="items">
        <Row>
          <Col>
            {/* <Card bgColor="#D73B3B" /> */}
            <div
              className="cardbox youtube"
              onClick={() => (window.location = "https://youtube.com/kdcloudy")}
            >
              <img
                style={{ marginTop: "80px" }}
                src={youtubelogo}
                height="40px"
              ></img>
            </div>
          </Col>
          <Col>
            {/* <Card bgColor="#6DB2E0" /> */}
            <div
              className="cardbox twitter"
              onClick={() => (window.location = "https://twitter.com/kdcloudy")}
            >
              <img
                style={{ marginTop: "70px" }}
                src={twitterLogo}
                height="70px"
              ></img>
            </div>
          </Col>
          <Col>
            {/* <Card bgColor="#0A66C2" /> */}
            <div
              className="cardbox linkedin"
              onClick={() =>
                (window.location = "https://linkedin.com/in/kdcloudy")
              }
            >
              <img
                style={{ marginTop: "80px" }}
                src={linkedinlogo}
                height="40px"
              ></img>
            </div>
          </Col>
          <Col>
            {/* <Card bgColor="#202830" />
             */}
            <div
              className="cardbox letterboxd"
              onClick={() =>
                (window.location = "https://letterboxd.com/kdcloudy/")
              }
            >
              <img
                style={{ marginTop: "40px" }}
                src={letterboxd}
                height="120px"
              ></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox spotify"
              onClick={() =>
                (window.location =
                  "https://open.spotify.com/user/21356mis3ijm26hoywsriopgy?si=8250521cc0784d7b")
              }
            >
              {" "}
              <img
                style={{ marginTop: "70px" }}
                src={spotify}
                height="60px"
              ></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox blog"
              onClick={() => (window.location = "https://blog.kdcloudy.com/")}
            >
              {" "}
              <img
                src={blog}
                style={{ marginTop: "25px", marginLeft: "-30px" }}
                height="150px"
              ></img>
            </div>
          </Col>

          <Col>
            <div
              className="cardbox github"
              onClick={() =>
                (window.location = "https://www.github.com/kdcloudy")
              }
            >
              {" "}
              <img
                style={{ marginTop: "70px" }}
                src={github}
                height="64px"
              ></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox tranzit"
              onClick={() =>
                (window.location = "https://www.tranzit-metro.com")
              }
            >
              <img src={tranzitlogo} height="80px"></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox hymnsense"
              onClick={() => (window.location = "https://www.hymnsense.com")}
            >
              <img
                style={{ marginTop: "80px" }}
                src={hymnsenselogo}
                height="40px"
              ></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox tradebase"
              onClick={() =>
                (window.location = "https://tradebase.herokuapp.com/")
              }
            >
              {" "}
              <img
                style={{ marginTop: "80px" }}
                src={tradebaseLogo}
                height="40px"
              ></img>
            </div>
          </Col>

          <Col>
            <div
              className="cardbox efflux"
              onClick={() =>
                (window.location =
                  "https://github.com/stars/kdcloudy/lists/efflux-apps")
              }
            >
              {" "}
              <img
                style={{ marginTop: "70px" }}
                src={effluxLogo}
                height="50px"
              ></img>
            </div>
          </Col>
          <Col>
            <div
              className="cardbox bajaj"
              onClick={() =>
                (window.location = "https://github.com/HNGP/bajaj-hackathon")
              }
            >
              {" "}
              <img
                style={{ marginTop: "70px" }}
                src={bajaj}
                height="60px"
              ></img>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer">
        &#169; 2022 Kaustubh Debnath. All rights reserved.{" "}
      </div>
    </div>
  );
}

export default App;
