import styled from "styled-components";

export default styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
  // box-shadow: 14px 14px 29px #1b1b1b, -14px -14px 29px #353535;
  width: 400px;
  height: 200px;
  margin-left: 50px;
  margin-top: 50px;
  transition: 0.5s;
  cursor: pointer;
}
`;
